import { BaseHttpService } from '@/services/base-http-service'
import { User } from '@/types'

class AuthService extends BaseHttpService {
    public async createUser(user: Partial<User>): Promise<Partial<User>> {
        const uri = '/auth-users'
        const response = await this.doApiPost<Partial<User>>(uri, JSON.stringify(user))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateUser(authUserId: string, user: Partial<User>): Promise<Partial<User>> {
        const uri = `/auth-users/${authUserId}`
        const response = await this.doApiPut<Partial<User>>(uri, JSON.stringify(user))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteUser(authUserId: string): Promise<boolean> {
        const uri = `/auth-users/${authUserId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async setUserPassword(authUserId: string, userEmail: string): Promise<boolean> {
        const uri = `/auth-users/${authUserId}/set-password`
        const response = await this.doApiPut<boolean>(uri, JSON.stringify({ userEmail }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getRoles(): Promise<string[]> {
        const uri = '/auth-roles'
        const response = await this.doApiGet<string[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getUserRoles(authUserId: string): Promise<string[]> {
        const uri = `/auth-users/${authUserId}/roles`
        const response = await this.doApiGet<string[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async addUserRole(authUserId: string, roleName: string): Promise<boolean> {
        const uri = `/auth-users/${authUserId}/roles/${roleName}`
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async removeUserRole(authUserId: string, roleId: string): Promise<boolean> {
        const uri = `/auth-users/${authUserId}/roles/${roleId}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new AuthService()
