import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { Epic, Vendor } from '@/types'
import VendorService from '@/services/vendor_service'

const toast = useToast()

interface VendorState {
    vendors: Vendor[]
}

export const useVendorStore = defineStore('bill', {
    state: (): VendorState => {
        return {
            vendors: [],
        }
    },

    actions: {
        async getVendors() {
            try {
                this.vendors = await VendorService.getVendors()
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },
    },
})
