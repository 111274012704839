import { BaseHttpService } from '@/services/base-http-service'
import { Vendor } from '@/types'

class VendorService extends BaseHttpService {
    public async getVendor(id: number): Promise<Vendor> {
        const uri = `/vendors/${id}`
        const response = await this.doApiGet<Vendor>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getVendors(): Promise<Vendor[]> {
        const uri = '/vendors'
        const response = await this.doApiGet<Vendor[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new VendorService()
