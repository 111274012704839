import { BaseHttpService } from '@/services/base-http-service'
import { QuickBooksService } from '@/types'

class QuickBooksServiceService extends BaseHttpService {
    public async getQuickBooksCategory(id: number): Promise<QuickBooksService> {
        const uri = `/quick-books-services/${id}`
        const response = await this.doApiGet<QuickBooksService>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getQuickBooksCategories(): Promise<QuickBooksService[]> {
        const uri = '/quick-books-services'
        const response = await this.doApiGet<QuickBooksService[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadQuickBooksServices(environment: 'development' | 'production'): Promise<boolean> {
        const uri = `/quick-books-services/load?environment=${environment}`
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new QuickBooksServiceService()
