import { BaseHttpService } from '@/services/base-http-service'
import { BillLineItem } from '@/types'

class BillLineItemService extends BaseHttpService {
    public async getBillLineItem(id: number): Promise<BillLineItem> {
        const uri = `/bill-line-items/${id}`
        const response = await this.doApiGet<BillLineItem>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateCommissionAdjustment(id: number, commissionAdjustment: number): Promise<BillLineItem> {
        const uri = `/bill-line-items/${id}`
        const response = await this.doApiPut<BillLineItem>(uri, JSON.stringify({ commissionAdjustment }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new BillLineItemService()
