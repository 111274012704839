import { ZohoDealCommission, ZohoDealAndCommissionFilter } from '@/types'
import { BaseHttpService } from '@/services/base-http-service'

class ZohoDealCommissionService extends BaseHttpService {
    public async getDealCommission(id: number): Promise<ZohoDealCommission> {
        const uri = `/zoho-deal-commissions/${id}`
        const response = await this.doApiGet<ZohoDealCommission>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getDealCommissions(filter: ZohoDealAndCommissionFilter): Promise<ZohoDealCommission[]> {
        const uri = '/zoho-deal-commissions/list'
        const response = await this.doApiPost<ZohoDealCommission[]>(uri, JSON.stringify({ filter }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async payCommissions(zohoDealCommissionId: number, invoiceIds: number[], isDev: boolean): Promise<boolean> {
        const uri = '/zoho-deal-commissions/pay-commissions'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ zohoDealCommissionId, invoiceIds, isDev }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async reverseCommissions(zohoDealCommissionId: number, invoiceIds: number[], isDev: boolean): Promise<boolean> {
        const uri = '/zoho-deal-commissions/reverse-commissions'
        const response = await this.doApiPost<boolean>(uri, JSON.stringify({ zohoDealCommissionId, invoiceIds, isDev }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ZohoDealCommissionService()
