export const colors: {name: string, textColor: string}[] = [
    { name: 'deeppink', textColor: 'white' },
    { name: 'teal', textColor: 'white' },
    { name: 'orange', textColor: 'black' },
    { name: 'green', textColor: 'white' },
    { name: 'blue', textColor: 'white' },
    { name: 'brown', textColor: 'black' },
    { name: 'purple', textColor: 'white' },
    { name: 'red', textColor: 'black' },
    { name: 'darkgoldenrod', textColor: 'black' },
    { name: 'magenta', textColor: 'black' },
    { name: 'yellow', textColor: 'black' },
    { name: 'pink', textColor: 'black' },
    { name: 'darkgreen', textColor: 'black' },
    { name: 'yellow', textColor: 'black' },
]

export const epicHeaders: Header[] = [
    {
        name: 'Name',
        colWidth: 2,
    },
    {
        name: 'Description',
        colWidth: 2,
    },
    {
        name: 'Start Date',
        colWidth: 1,
    },
    {
        name: 'End Date',
        colWidth: 1,
    },
    {
        name: 'Contract Type',
        colWidth: 1,
    },
    {
        name: 'Notes',
        colWidth: 1,
    },
    {
        name: 'Jira Key',
        colWidth: 1,
    },
    {
        name: 'Jira Id',
        colWidth: 1,
    },
    {
        name: 'Jira Status',
        colWidth: 1,
    },
    {
        name: 'QuickBooks Status',
        colWidth: 1,
    },
]

export const epicTicketHeaders: Header[] = [
    {
        name: 'Jira Key',
        colWidth: 1,
    },
    {
        name: 'Name',
        colWidth: 2,
    },
    {
        name: 'Owner',
        colWidth: 1,
    },
    {
        name: 'Service Item',
        colWidth: 3,
    },
    {
        name: 'Jira Status',
        colWidth: 1,
    },
    {
        name: 'Story Points',
        colWidth: 1,
    },
    {
        name: 'Original / Remaining / Actual (Hrs)',
        colWidth: 2,
    },
    {
        name: 'Over/Under (Hrs)',
        colWidth: 1,
    },
]

export const ticketHeaders: Header[] = [
    {
        name: 'Jira Key',
        colWidth: 1,
    },
    {
        name: 'Name',
        colWidth: 3,
    },
    {
        name: 'Epic',
        colWidth: 1,
    },
    {
        name: 'Owner',
        colWidth: 1,
    },
    {
        name: 'Service Item',
        colWidth: 3,
    },
    {
        name: 'Jira Status',
        colWidth: 1,
    },
]

export enum ZohoModule {
    Clients = 'Accounts',
    Deals = 'Deals',
    Vendors = 'Vendors',
}

export enum CommissionType {
    ExecutiveCoaching = 'executive_coaching',
    LeadGenSales = 'lead_gen_sales',
    PrimarySupport = 'primary_support',
    Sales = 'sales',
}

export enum CommissionTypeBackupRate {
    ExecutiveCoaching = 125,
    LeadGenSales = 0,
    PrimarySupport = 75,
    Sales = 0,
}

export enum CommissionTypePercent {
    ExecutiveCoaching = 62,
    LeadGenSales = 5,
    PrimarySupport = 37,
    Sales = 13,
}

export enum CommissionDiscountType {
    Dollar = 'dollar',
    Percent = 'percent',
}

export enum CommissionContractedBudgetType {
    Month = 'month',
    Project = 'project',
}

export enum ContractTemplateType {
    Client = 'client',
    Deal = 'deal',
    Vendor = 'vendor',
}

export enum ContractTemplateUploadType {
    DocX = 'docx',
    Rtf = 'rtf',
}

export enum ZohoCrmDataType {
    Client = 'client',
    Deal = 'deal',
}

export enum SpecialFormatName {
    Break = 'break',
    Indent = 'indent',
    Split = 'split',
}

export enum TaskIssueInformationFrequency {
    BiWeekly = 'bi_weekly',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export enum JiraProduct {
    ServiceDesk = 'jira-servicedesk',
    Software = 'jira-software',
}

export enum JiraUserGroup {
    ZeytechUsers = 'Zeytech Users',
}

export enum AlertFrequency {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export enum TSheetsApproveSubmitType {
    Approve = 'approve',
    Unapprove = 'unapprove',
    Submit = 'submit',
    Unsubmit = 'unsubmit',
}

export enum PdfAction {
    Download = 'download',
    Email = 'email',
}

export enum FileOrientation {
    Landscape = 'landscape',
    Portrait = 'portrait',
}

export enum WorklogViewType {
    Date = 'date',
    Epic = 'epic',
}

export enum StickyNoteStatus {
    ToDo = 'to_do',
    InProgress = 'in_progress',
    Done = 'done',
    InJira = 'in_jira',
}

export enum AlertType {
    TicketOverage = 'ticket_overage',
    SupportContractUtilization = 'support_contract_utilization',
    ResourcePlanUtilization = 'resource_plan_utilization',
}

export enum SplitType {
    BulletPoints = 'bullet_points',
    Newline = 'newline',
}

export enum ActivityTimelineType {
    Epic = 'epic',
    NewProject = 'newProject',
    Project = 'project',
    User = 'user',
}

export enum BillingCategory {
    Billable = 'billable',
    NonBillable = 'non_billable',
}

export enum FileType {
    Excel = 'xlsx',
    ExcelLegacy = 'xls',
    CSV = 'csv',
    Word = 'docx',
    RichText = 'rtf',
}

export enum DateType {
    StartDate = 'startDate',
    EndDate = 'endDate',
}

export enum ContractType {
    ResourcePlan = 'resource_plan',
    Schedule = 'schedule',
    SupportPlan = 'support_plan',
    StatementOfWork = 'statement_of_work',
}

export enum IssueGroup {
    DayOff = 'day_off',
    Epic = 'epic',
    Ticket = 'ticket',
    Unassigned = 'unassigned',
}

export enum TimesheetType {
    Manual = 'manual',
    Regular = 'regular',
}

export enum ProjectTypeKey {
    Software = 'software',
    ServiceDesk = 'service_desk',
    Other = 'other',
}

export enum RecordStatus {
    Active = 'active',
    Inactive = 'inactive',
    All = 'all',
}

export enum ResourcePlanType {
    Weekly = 'weekly',
    BiWeekly = 'bi_weekly',
    Monthly = 'monthly',
    Project = 'project',
}

export enum SupportPlanType {
    Hourly = 'hourly',
    BaseUnlimited = 'base_unlimited',
    BaseThenOverage = 'base_then_overage',
    BasePlusHourly = 'base_plus_hourly',
}

export interface BarStyle {
    color: string
    borderRadius: string
    backgroundColor: string
    fontSize?: string
}

export interface MonthRange {
    month: number
    year: number
}

export interface Option {
    text: string
    value: string|number|Report|ServiceItem|ResourcePlanDetailEpicServiceItem|SupportPlanDetailEpicServiceItem|
        ResourcePlanDetailEpicServiceItemSelection|SupportPlanDetailEpicServiceItemSelection|Partial<Sprint>|null
}

export interface ResourcePlanDetailEpicServiceItemSelection {
    resourcePlanDetailId: number
    epicServiceItemId: number
}

export interface SupportPlanDetailEpicServiceItemSelection {
    supportPlanDetailId: number
    epicServiceItemId: number
}

export interface Header {
    name: string
    colWidth: number|string
}

export interface PotentialFutureTask {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    description: string
    projectId: number
    sortOrder: number
    project?: Project
}

export interface TimeEntryPayPeriod {
    type: 'previous'|'current'|'future'
    startDate: Date
    endDate: Date
    rangeString: string
}

export interface TimeEntryPayPeriodTab {
    date: Date
    tabName: string
}

export interface Report {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    appText: string
    htmlViewLocation: string
    viewFilename: string
    includeInAppSelection: boolean
    active: boolean
    reportEmails?: ReportEmail[]
    reportSignature?: ReportSignature
}

export interface ReportEmail {
    id: number
    createdAt: Date
    updatedAt: Date
    reportId: number
    emailAddresses: string[]
    ccEmailAddresses: string[]
    addressedTo: string
    fromUserId: number
    projectId: number
    active: boolean
    showFullText: boolean
    fromUser?: User
    report?: Report
    project?: Project
}

export interface ReportSignature {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    reportId: number
    jobTitle: string
    businessAddress: string
    businessPhoneNumber: string
    mobilePhoneNumber: string
    businessWebsiteUrl: string
    linkedInUrl: string
    businessWebsiteText: string
    linkedInText: string
    logoUrl: string
    report?: Report
}

export interface Epic {
    id: number
    createdAt: Date
    updatedAt: Date
    projectId: number
    name: string
    description: string
    contractType: ContractType
    jiraId: string
    jiraKey: string
    statusId: number
    active: boolean
    notes: string
    startDate: Date|null
    endDate: Date|null
    tsheetsId: string|null
    quickBooksId: string|null
    version: number
    zohoDealId: number|null
    isDev: boolean
    project?: Project
    status?: Status
    zohoDeal?: ZohoDeal
    activityTimeline?: ActivityTimeline
    tickets?: Ticket[]
    serviceItems?: ServiceItem[]
    epicServiceItems?: EpicServiceItem[]
    epicUsers?: EpicUser[]
    devInvoices?: InvoiceDev[]
    prodInvoices?: Invoice[]
    invoices?: Invoice[]|InvoiceDev[]
    resourcePlanDetails?: ResourcePlanDetail[]
    supportPlanDetails?: SupportPlanDetail[]
    devBillLineItems?: BillLineItemDev[]
    prodBillLineItems?: BillLineItem[]
    billLineItems?: BillLineItem[]|BillLineItemDev[]
}

export interface EpicUser {
    id: number
    createdAt: Date
    updatedAt: Date
    epicId: number
    userId: number
    tsheetsAssignmentId: number|null
    epic?: Epic
    user?: User
}

export interface EditingEpicUser {
    id: number
    fullName: string
    userId: number
    tsheetsUserId: string
    epicId: number
    tsheetsEpicId: string
    tsheetsAssignmentId: number
    isChecked: boolean
}

export interface CategorySkill {
    category: string
    skills: Skill[]
}

export interface Skill {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    activityTimelineId: string
    category: string
}

export interface SkillLevel {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    activityTimelineId: string
}

export interface UserSkill {
    id: number
    createdAt: Date
    updatedAt: Date
    userId: number
    skillId: number
    skillLevelId: number
    user?: User
    skill?: Skill
    skillLevel?: SkillLevel
}

export interface OpenUser {
    [key: number]: {
        userSkills: UserSkill[]
    }
}

export interface OpenSkill {
    [key: number]: {
        userSkills: UserSkill[]
    }
}

export interface OpenTicket {
    [key: number]: {
        index: number
    }
}

export interface OpenHours {
    [key: number]: {
        index: number
    }
}

export interface OpenWorklogs {
    [key: number]: {
        worklogs: Worklog[]
    }
}

export interface EpicDetail {
    [key: number]: {
        index: number
    }
}

export interface TicketDetail {
    [key: number]: {
        index: number
    }
}

export interface AddedDeletedServiceItem {
    [key: string]: ServiceItem
}

export interface UserResourcePlanEnabledStatus {
    [key: number]: {
        [key: number]: boolean
    }
}

export interface EditingResourcePlanDetail {
    [key: number]: {
        [key: number]: {
            isEditing: boolean
            resourcePlanDetail: {
                epicId: number
                id: number
                resourcePlanType: ResourcePlanType|undefined
                budgetSeconds: number
                budgetHours: number
                budgetDollars: number
                name: string
                userId: number
                active: boolean
                enabledUsersOnly: boolean
                createdByUser: User
                resourcePlanDetailEpicServiceItems: Partial<ResourcePlanDetailEpicServiceItem>[]
            },
        },
    }
}

export interface EditingResourcePlanDetailEpicServiceItem {
    [key: number]: {
        [key: number]: {
            resourcePlanDetailId: number
            epicServiceItemId: number
        }[]
    }
}

export interface NewResourcePlanDetail {
    epicId: number
    epicIndex: number
    epicServiceItems: EpicServiceItem[]
    name: string
    resourcePlanType: ResourcePlanType
    budgetHours: number
    budgetSeconds: number
    budgetDollars: number
    active: boolean
    enabledUsersOnly: boolean
    resourcePlanDetailEpicServiceItems: EpicServiceItem[]
}

export interface ResourcePlanDetail {
    createdAt: Date
    updatedAt: Date
    id: number
    epicId: number
    resourcePlanType: ResourcePlanType
    budgetSeconds: number
    budgetDollars: number
    userId: number
    name: string
    active: boolean
    enabledUsersOnly: boolean
    epic?: Epic
    resourcePlanDetailEpicServiceItems?: Partial<ResourcePlanDetailEpicServiceItem>[]
    createdByUser?: User
    users?: User[]
}

export interface ResourcePlanDetailUser {
    createdAt: Date
    updatedAt: Date
    id: number
    resourcePlanDetailId: number
    userId: number
    resourcePlanDetail?: ResourcePlanDetail
    user?: User
}

export interface ResourcePlanDetailEpicServiceItem {
    createdAt: Date
    updatedAt: Date
    id: number
    resourcePlanDetailId: number
    epicServiceItemId: number
    resourcePlanDetail?: ResourcePlanDetail
    epicServiceItem?: EpicServiceItem
}

export interface UserSupportPlanEnabledStatus {
    [key: number]: {
        [key: number]: boolean
    }
}

export interface EditingSupportPlanDetail {
    [key: number]: {
        [key: number]: {
            isEditing: boolean
            supportPlanDetail: {
                epicId: number
                id: number
                supportPlanType: SupportPlanType|undefined
                baseServiceItem: ServiceItem|undefined
                budgetSeconds: number
                budgetHours: number
                budgetDollars: number
                basePreOverageSeconds: number
                basePreOverageHours: number
                name: string
                userId: number
                createdByUser: User
                active: boolean
                enabledUsersOnly: boolean
                supportPlanDetailEpicServiceItems: Partial<SupportPlanDetailEpicServiceItem>[]
            },
        },
    }
}

export interface EditingSupportPlanDetailEpicServiceItem {
    [key: number]: {
        [key: number]: {
            supportPlanDetailId: number
            epicServiceItemId: number
        }[]
    }
}

export interface NewSupportPlanDetail {
    epicId: number
    epicIndex: number
    name: string
    supportPlanType: SupportPlanType,
    epicServiceItems: EpicServiceItem[]
    baseServiceItem: ServiceItem|undefined
    basePreOverageHours: number
    basePreOverageSeconds: number
    budgetHours: number
    budgetSeconds: number
    budgetDollars: number
    active: boolean
    enabledUsersOnly: boolean
    supportPlanDetailEpicServiceItems: EpicServiceItem[]
}

export interface BudgetInformation {
    [key: string]: EpicResourceSupportPlanStatusInformation[]
}

export interface EpicResourceSupportPlanStatusInformation {
    dateString: string
    date: Date|null
    planName: string
    epicId: number
    trackingType: 'dollars'|'hours'|''
    percent: number
    message: string
    usedValue: number
    totalValue: number
}

export interface SupportPlanDetail {
    createdAt: Date
    updatedAt: Date
    id: number
    epicId: number
    supportPlanType: SupportPlanType,
    baseServiceItemId: number
    basePreOverageSeconds: number
    budgetSeconds: number
    budgetDollars: number
    userId: number
    name: string
    active: boolean
    enabledUsersOnly: boolean
    epic?: Epic
    baseServiceItem?: ServiceItem
    supportPlanDetailEpicServiceItems?: Partial<SupportPlanDetailEpicServiceItem>[]
    createdByUser?: User
    users?: User[]
}

export interface SupportPlanDetailUser {
    createdAt: Date
    updatedAt: Date
    id: number
    supportPlanDetailId: number
    userId: number
    supportPlanDetail?: SupportPlanDetail
    user?: User
}

export interface SupportPlanDetailEpicServiceItem {
    createdAt: Date
    updatedAt: Date
    id: number
    supportPlanDetailId: number
    epicServiceItemId: number
    supportPlanDetail?: SupportPlanDetail
    epicServiceItem?: EpicServiceItem
}

export interface Ticket {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    description: string
    jiraId: string
    jiraKey: string
    originalEstimateSeconds: number
    remainingEstimateSeconds: number
    actualSeconds: number
    userId: number|null
    reporterUserId: number|null
    createdByUserId: number|null
    epicServiceItemId: number
    statusId: number
    ignoreOverageAlert: boolean
    hasMultipleServiceItems: boolean
    dateUpdated: Date
    storyPointValue: number|null
    projectId: number
    latestNotes: string
    beginDate: Date|string|null
    endDate: Date|string|null
    labels: string[]
    issueTypeId: number
    user?: User
    reporterUser?: User
    createdByUser?: User
    project?: Project
    epicServiceItem?: EpicServiceItem
    status?: Status
    sprints?: Sprint[]
    worklogs?: Worklog[]
    activityTimelines?: ActivityTimeline[]
    sprintTickets?: SprintTicket[]
    boardTickets?: BoardTicket[]
    boards?: Board[]
    issueType?: IssueType
}

export interface Board {
    createdAt: Date
    updatedAt: Date
    id: number
    jiraId: number
    name: string
    type: string
    projectId: number|null
    isMainProjectBoard: boolean
    project?: Project
    sprints?: Sprint[]
    tickets?: Ticket[]
    boardTickets?: BoardTicket[]
}

export interface Sprint {
    createdAt: Date
    updatedAt: Date
    id: number
    jiraId: number
    name: string
    state: string
    startDate: Date|string
    endDate: Date|string
    completeDate: Date|string|null
    boardId: number
    tickets?: Ticket[]
    sprintTickets?: SprintTicket[]
    board?: Board
}

export interface BoardTicket {
    createdAt: Date
    updatedAt: Date
    id: number
    boardId: number
    ticketId: number
    rank: number
    board?: Board
    ticket?: Ticket
}

export interface SprintTicket {
    createdAt: Date
    updatedAt: Date
    id: number
    sprintId: number
    ticketId: number
    rank: number
    sprint?: Sprint
    ticket?: Ticket
}

export interface ProjectSprintsAndBacklog {
    budgetInformation: BudgetInformation
    projectWithBacklogTickets: Project
    sprints: Sprint[]
}

export interface QuickBooksCategory {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    quickBooksId: string
    active: boolean
    version: number
    parentRefValue: string
    quickBooksCategoryId: number
    quickBooksCategory?: QuickBooksCategory
    quickBooksCategories?: QuickBooksCategory[]
}

export interface QuickBooksCategoryDev {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    quickBooksId: string
    active: boolean
    version: number
    parentRefValue: string
    quickBooksCategoryId: number
    quickBooksCategory?: QuickBooksCategoryDev
    quickBooksCategories?: QuickBooksCategoryDev[]
}

export interface QuickBooksService {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    quickBooksId: string
    active: boolean
    version: number
    quickBooksCategoryId: number
    unitPrice: number
    isTaxable: boolean
    serviceItemSalesType: { value: string; name: string }
    quickBooksCategory?: QuickBooksCategory
}

export interface QuickBooksServiceDev {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    quickBooksId: string
    active: boolean
    version: number
    quickBooksCategoryId: number
    unitPrice: number
    isTaxable: boolean
    serviceItemSalesType: { value: string; name: string }
    quickBooksCategory?: QuickBooksCategoryDev
}

export interface Collaborator {
    accountId: string
}

export interface Issue {
    projectKey: string,
    issueType: string,
    summary: string,
    description?: string,
    collaborators?: Collaborator[]
    labels?: string[],
    epicKey?: string
    serviceItem?: string
    epicName?: string
    epicContractType?: string
    beginDate?: string
    endDate?: string
    assigneeJiraId?: string
    originalEstimateSeconds?: number
    remainingEstimateSeconds?: number
}

export interface StickyNote {
    createdAt: Date
    updatedAt: Date
    id: number
    userId: number
    note: string
    width: number
    height: number
    x: number
    y: number
    status: StickyNoteStatus
    name: string
    jiraKey: string
    user?: User
}

export interface UserStickyNoteSetting {
    createdAt: Date
    updatedAt: Date
    id: number
    userId: number
    showToDo: boolean
    showInProgress: boolean
    showDone: boolean
    showInJira: boolean
    user?: User
}

export interface ServiceItem {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    rate: number
    active: boolean
    notes: string
    tsheetsId: string|null
    tsheetsInactiveDate: Date|null
    quickBooksInactiveDate: Date|null
    quickBooksServiceId: number|null
    quickBooksServiceDevId: number|null
    isTaxable: boolean
    finishedTsheetsSetup: boolean
    serviceItemSalesType: { value: string; name: string }
    quickBooksService?: QuickBooksService|QuickBooksServiceDev
    quickBooksServiceDev?: QuickBooksServiceDev
    quickBooksServiceProd?: QuickBooksService
    epicServiceItems?: EpicServiceItem[]
    epics?: Epic[]
    serviceItemUsers?: ServiceItemUser[]
    users?: User[]
}

export interface QuickBooksPartialPropertyObject {
    value: string
}

export interface NewQuickBooksService {
    name: string
    parentRef: QuickBooksPartialPropertyObject
    unitPrice: number
    isTaxable: boolean
    serviceItemSalesType: { value: string; name: string }
}

export interface UpdateQuickBooksService extends NewQuickBooksService {
    quickBooksId: string
    active: boolean
    version: number
    isDev: boolean
}

export interface ServiceItemUser {
    createdAt: Date
    updatedAt: Date
    id: number
    serviceItemId: number
    userId: number
    tsheetsFilterId: number|null
    serviceItem?: ServiceItem
    user?: User
}

export interface EditingServiceItemUser {
    id: number
    fullName: string
    userId: number
    tsheetsUserId: string
    serviceItemName: string
    serviceItemId: number
    tsheetsServiceItemId: string
    tsheetsFilterId: number|null
    isChecked: boolean
}

export interface NewTSheetsServiceItemUser {
    serviceItemId: number
    tsheetsServiceItemId: string
    userId: number
    tsheetsUserId: string
}

export interface EpicServiceItem {
    createdAt: Date
    updatedAt: Date
    id: number
    notes: string
    epicId: number
    serviceItemId: number
    tsheetsFilterId: number
    epic?: Epic
    serviceItem?: ServiceItem
    recurringTasks?: RecurringTask
    tickets?: Ticket[]
    resourcePlanDetailEpicServiceItems?: ResourcePlanDetailEpicServiceItem[]
    supportPlanDetailEpicServiceItems?: SupportPlanDetailEpicServiceItem[]
}

export interface ServiceDeskQueue {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    jiraId: string
    jql: string
}

export interface Organization {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    projectId: number
    jiraId: number
    serviceDeskQueueId: number
    project?: Project
    serviceDeskQueue?: ServiceDeskQueue
    customers?: Customer[]
    keywords?: OrganizationKeyWord[]
}

export interface OrganizationKeyWord {
    createdAt: Date
    updatedAt: Date
    id: number
    keyWord: string
    organizationId: number
    shouldRemove?: boolean
    organization?: Organization
}

export interface NewOrganizationServiceDesk {
    organization: Organization
    createdJiraQueue: boolean
    createdDatabaseQueue: boolean
    createdDatabaseCustomers: boolean
    errorMessages: string[]
}

export interface ZohoClient {
    createdAt: Date
    updatedAt: Date
    id: number
    friendlyName: string
    name: string
    zohoId: string
    contactFirstName: string|null
    contactLastName: string|null
    contactEmail: string|null
    street1: string|null
    street2: string|null
    city: string|null
    state: string|null
    postalCode: string|null
    country: string|null
    active: boolean
    project?: Project
    zohoDeals?: ZohoDeal[]
}

export interface ZohoDeal {
    createdAt: Date
    updatedAt: Date
    id: number
    dealName: string
    description: string|null
    zohoClientId: number|null
    zohoId: string
    commissionContractedBudgetMonthPackage: number|null
    commissionContractedBudgetTypeMonthPackage: CommissionContractedBudgetType|null
    commissionContractedBudgetHour: string|null
    commissionEffectiveDate: string|null
    commissionRenewalDate: string|null
    commissionSubContractorFees: number|null
    commissionSubContractorFeeNotes: string|null
    commissionDiscounts: number|null
    commissionDiscountType: CommissionDiscountType|null
    commissionDiscountNotes: string|null
    dealSpecialNotes: string|null
    isDev: boolean
    zohoClient?: ZohoClient
    zohoDealCommissions?: ZohoDealCommission[]
    epics?: Epic[]
    devInvoices?: InvoiceDev[]
    prodInvoices?: Invoice[]
    invoices?: Invoice[]|InvoiceDev[]
    devBillLineItems?: BillLineItemDev[]
    prodBillLineItems?: BillLineItem[]
    billLineItems?: BillLineItem[]|BillLineItemDev[]
    [index: string]: Date | number | string | boolean | CommissionContractedBudgetType | CommissionDiscountType | ZohoClient |
        ZohoDealCommission[] | Epic[] | Invoice[] | InvoiceDev[] | BillLineItem[] | BillLineItemDev[] | null | undefined
}

export interface ZohoDealCommission {
    createdAt: Date
    updatedAt: Date
    id: number
    zohoDealId: number
    commissionType: CommissionType
    commissionAssignmentUserId: number
    commissionSpecialNotes: string|null
    commissionOffGrossProfit: number
    zohoDeal?: ZohoDeal
    commissionAssignmentUser?: User
    [index: string]: Date | string | number | CommissionType | ZohoDeal | User | null | undefined
}

export interface Project {
    createdAt: Date
    updatedAt: Date
    id: number
    key: string
    jiraId: string
    name: string
    projectTypeKey: ProjectTypeKey
    techLeadId: number|string|null
    accountManagerId: number|string|null
    serviceDeskOwnerId: number|string|null
    tsheetsId: string|null
    quickBooksId: string|null
    version: number
    active: boolean
    planningNotes: string
    isDev: boolean
    tickets?: Ticket[]
    sprintTickets?: Ticket[]
    organization?: Organization
    projectUsers?: ProjectUser[]
    projectServiceItems?: ProjectServiceItem[]
    epics?: Epic[]
    activityTimelines?: ActivityTimeline[]
    techLead?: User
    accountManager?: User
    serviceDeskOwner?: User
    boards?: Board[]
    client?: ZohoClient
}

export interface ProjectUser {
    createdAt: Date
    updatedAt: Date
    id: number
    projectId: number
    userId: number
    tsheetsAssignmentId: number|null
    project?: Project
    user?: User
}

export interface ProjectServiceItem {
    createdAt: Date
    updatedAt: Date
    id: number
    projectId: number
    serviceItemId: number
    tsheetsFilterId: number|null
}

export interface EditingProjectUser {
    id: number
    fullName: string
    userId: number
    tsheetsUserId: string
    projectName: string
    projectId: number
    tsheetsProjectId: string
    tsheetsAssignmentId: number
    isChecked: boolean
}

export interface JiraGroup {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    jiraId: string
    includeInApp: boolean
    isZeytechEmployeeGroup: boolean
}

export interface UserWorklogs {
    [key: string]: {
        [key: string]: {
            totalSeconds: number
            worklogs: Worklog[]
        }
    }
}

export interface UserTimesheetsByPayPeriod {
    [key: string]: {
        user: User
        userTimesheet: UserTimesheet
    }
}

export interface UserTimesheet {
    [key: string]: {
        totalSeconds: number
        timesheets: Timesheet[]
    }
}

export interface UserTimesheets {
    [key: string]:  {
        user: User
        timesheets: Timesheet[]
    }
}

export interface EditingProject {
    [key: number]: {
        isEditing: boolean
        techLeadId: number|null
        accountManagerId: number|null
    }
}

export interface EditingTimesheetDetail {
    [key: string]: {
        isEditing: boolean
        id: string
        serviceItem: string
        newServiceItem: string
        billable: 'Yes'|'No'
    }
}

export interface Timesheet {
    id: string
    date: string
    duration: number
    notes: string
    type: TimesheetType
    project: string
    epic: string
    serviceItem: string
    hasMultipleServiceItems: boolean
    availableServiceItems: string[]
    billable: 'Yes'|'No'
    jobCodeType: string
    startDate?: Date
    endDate?: Date
}

export interface TimeOffRequest {
    tsheetsUserId: string
    notes: string
    data: TimeOffRequestData[]
}

export interface TimeOffRequestData {
    date: string
    hours: number
    seconds: number
    timeOffTypeId: string
}

export interface Status {
    createdAt: Date
    updatedAt: Date
    id: number
    jiraId: string
    name: string
    category: string
}

export interface User {
    createdAt: Date
    updatedAt: Date
    id: number
    email: string
    fullName: string
    jobTitle: string
    authUserId: string
    jiraId: string
    active: boolean
    useInPlanning: boolean
    showInPlanning: boolean
    isZeytechEmployee: boolean
    tsheetsId: string|null
    submittedTo: Date|null
    approvedTo: Date|null
    isManagedServices: boolean
    firstName: string
    middleName: string
    lastName: string
    quickBooksId: string
    role: string
    createdResourcePlanDetails?: ResourcePlanDetail[]
    createdSupportPlanDetails?: SupportPlanDetail[]
    resourcePlanDetails?: ResourcePlanDetail[]
    supportPlanDetails?: SupportPlanDetail[]
    tickets?: Ticket[]
    reporterTickets?: Ticket[]
    techLeadProjects?: Project[]
    accountManagerProjects?: Project[]
    activityTimelines?: ActivityTimeline[]
    userSkills?: UserSkill[]
    worklogs?: Worklog[]
    stickyNotes?: StickyNote[]
    reportEmails?: ReportEmail[]
    projects?: Project[]
    serviceItems?: ServiceItem[]
    timeOffTypes?: TimeOffType[]
    jeopardyTeams?: JeopardyTeam[]
    zohoDealCommissions?: ZohoDeal[]
}

export interface IssueType {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    activityTimelineId: string|null
    jiraId: string
    issueGroup?: IssueGroup
}

export interface TimeOffType {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    tsheetsId: string
    active: boolean
}

export interface TimeOffTypeUser {
    createdAt: Date
    updatedAt: Date
    id: number
    timeOffTypeId: number
    userId: number
    tsheetsAssignmentId: number
    timeOffType?: TimeOffType
    user?: User
}

export interface Alert {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    daysOfWeekOrMonth: number[]
    frequency: AlertFrequency
    lastRunDate: Date
    active: boolean
    hasExtraChecks: boolean
    alertLogs?: AlertLog[]
}

export interface AlertLog {
    createdAt: Date
    updatedAt: Date
    id: number
    firstAlertDate: Date
    latestAlertDate: Date
    allAlertDates: string[]
    firstAcknowledgeDate: Date
    latestAcknowledgeDate: Date
    alertId: number
    epicId: number
    ticketId: number
    message: string
    rangeString: string|null
    rangeMet: number|null
    epic?: Epic
    ticket?: Ticket
    alert?: Alert
}

export interface UserPlanningActivityTimeline extends User {
    fullName: string
    planningActivityTimelines?: PlanningActivityTimeline[]
}

export interface PlanningActivityTimeline {
    id: number
    attachedToId: number|null
    startDate: Date
    endDate: Date
    totalHours: number
    description: string
    originalEstimateSeconds: number
    timePlannedSeconds: number|null
}

export interface ActivityTimeline {
    createdAt: Date
    updatedAt: Date
    id: number
    attachedToJiraId: string|null
    attachedToId: number|null
    issueTypeId: number
    userId: number
    activityTimelineId: string
    jiraId: string|null
    jiraKey: string
    projectId: number|null
    description: string
    originalEstimateSeconds: number
    remainingEstimateSeconds: number
    timePlannedSeconds: number
    startDate: Date|string|null
    endDate: Date|string|null
    user?: User
    issueType?: IssueType
    project?: Project
    ticket?: Ticket
    activityTimeline?: ActivityTimeline
    activityTimelines?: ActivityTimeline[]
}

export interface Worklog {
    createdAt: Date
    updatedAt: Date
    id: number
    worklogId: number
    userId: number
    billingCategory: BillingCategory
    ticketId: number
    timeSpentSeconds: number
    date: Date
    comment: string
    appliedInvoiceZohoCommissionId: number|null
    appliedInvoiceZohoCommissionDevId: number|null
    user?: User
    ticket?: Ticket
    devAppliedInvoiceZohoCommission?: AppliedInvoiceZohoCommissionDev
    prodAppliedInvoiceZohoCommission?: AppliedInvoiceZohoCommission
    appliedZohoCommission?: AppliedInvoiceZohoCommission|AppliedInvoiceZohoCommissionDev
}

export interface NewWorklog {
    jiraKey: string
    userId: number
    date: string
    comment: string
    hours: number
    minutes: number
    billingCategory: BillingCategory
}

export interface EditingWorklog {
    jiraKey: string
    userId: number
    ticketId: number
    date: string
    comment: string
    hours: number
    minutes: number
    timeSpentSeconds: number
    billingCategory: BillingCategory
    worklogId: number
}

export interface EditingActivityTimeline {
    id: number
    jiraId: string
    jiraKey: string
    originalEstimateSeconds: number
    originalHours: number
    originalMinutes: number
    remainingEstimateSeconds: number
    remainingHours: number
    remainingMinutes: number
    originalStartDate: string
    startDate: string
    originalEndDate: string
    endDate: string
    ticketId: number
    isDone: boolean
    [index: string]: number | string | boolean
}

export interface ReportWorklog {
    category: string
    person: string
    project: string
    issue: string
    timeSpent: number
    date: Date
    comment: string
}

export interface ReportProjectWorklog {
    epic: string
    epicName: string
    worklogs: {
        billable: ReportWorklog[],
        billableTotal: number,
        nonBillable: ReportWorklog[],
        nonBillableTotal: number,
    },
}

export interface AgileStoryPointMeasure {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    description: string
    sortOrder: number
    agileStoryPointLevels?: AgileStoryPointLevel[]
    agileStoryPointMeasureExamples?: AgileStoryPointMeasureExample[]
}

export interface AgileStoryPointLevel {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    description: string
    levelOrder: number
    agileStoryPointMeasureId: number
    agileStoryPointMeasure?: AgileStoryPointMeasure
    agileStoryPointLevelExamples?: AgileStoryPointLevelExample[]
}

export interface AgileStoryPointMeasureExample {
    createdAt: Date
    updatedAt: Date
    id: number
    agileStoryPointMeasureId: number
    example: string
    agileStoryPointMeasure?: AgileStoryPointMeasure
}

export interface AgileStoryPointLevelExample {
    createdAt: Date
    updatedAt: Date
    id: number
    agileStoryPointLevelId: number
    example: string
    agileStoryPointLevel?: AgileStoryPointLevel
}

export interface AgileStoryPointLevelValue {
    createdAt: Date
    updatedAt: Date
    id: number
    measureLevelGroup: number[]
    storyPointValue: number
    sortOrder: number
}

export interface Setting {
    createdAt: Date
    updatedAt: Date
    id: number
    name: string
    value: boolean|number|object|string|string[]|number[]
    jsonValue: object
}

export interface SettingsDto {
    actualSecondsOverageAlertValue: number
    defaultSupportRate: number
    estimateRemainingSecondsOverageAlertValue: number
    resourceBudgetTriggerPercentages: number[]
    supportProfitabilityTriggerPercentages: number[]
}

export interface JeopardyGame {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    gameDate: Date|string
    gameStarted: boolean
    gameEnded: boolean
    isDailyDouble: boolean
    dailyDoubleJeopardyQuestionId: number
    dailyDoubleWagerSubmitted: boolean
    isFinalJeopardy: boolean
    finalJeopardyCategory: string
    finalJeopardyQuestion: string
    finalJeopardyResponse: string
    finalJeopardyResponseChoices: string[]
    numFinalJeopardyTeams: number
    numFinalJeopardyWagers: number
    numFinalJeopardyResponses: number
    choosingJeopardyTeamId: number
    buzzedInJeopardyTeamId: number|null
    winningJeopardyTeamId: number
    showDailyDoubleWager: boolean
    jeopardyCategories?: JeopardyCategory[]
    jeopardyTeams?: JeopardyTeam[]
    choosingJeopardyTeam?: JeopardyTeam
    buzzedInJeopardyTeam?: JeopardyTeam
    winningJeopardyTeam?: JeopardyTeam
    dailyDoubleJeopardyQuestion?: JeopardyQuestion
}

export interface JeopardyCategory {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    order: number
    isFlipped: boolean
    jeopardyGameId: number
    jeopardyGame?: JeopardyGame
    jeopardyQuestions?: JeopardyQuestion[]
}

export interface JeopardyQuestion {
    id: number
    createdAt: Date
    updatedAt: Date
    question: string
    pointValue: number
    categoryOrder: number
    answer: string
    answerChoices: string[]
    isCurrentQuestion: boolean
    complete: boolean
    isDailyDouble: boolean
    dailyDoubleJeopardyTeamId: number|null
    jeopardyCategoryId: number
    jeopardyCategory?: JeopardyCategory
    jeopardyTeams?: JeopardyTeam[]
    jeopardyQuestionTeams?: JeopardyQuestionTeam[]
    dailyDoubleJeopardyTeam?: JeopardyTeam
}

export interface JeopardyTeam {
    id: number
    createdAt: Date
    updatedAt: Date
    teamName: string
    jeopardyGameId: number
    buzzerLocked: boolean
    isDailyDouble: boolean
    inFinalJeopardyRound: boolean
    finalJeopardyWager: number|null
    finalJeopardyResponse: string|null
    finalJeopardyCorrect: boolean|null
    finalJeopardyWagerShown: boolean
    finalJeopardyResponseShown: boolean
    addWinningPoint: boolean
    jeopardyGame?: JeopardyGame
    jeopardyTeamUsers?: JeopardyTeamUser[]
    jeopardyQuestionTeams?: JeopardyQuestionTeam[]
    users?: User[]
    jeopardyQuestions?: JeopardyQuestion[]
}

export interface JeopardyTeamUser {
    id: number
    createdAt: Date
    updatedAt: Date
    jeopardyTeamId: number
    userId: number
    jeopardyTeam?: JeopardyTeam
    user?: User
}

export interface JeopardyQuestionTeam {
    id: number
    createdAt: Date
    updatedAt: Date
    jeopardyQuestionId: number
    jeopardyTeamId: number
    isCorrect: boolean
    jeopardyQuestion?: JeopardyQuestion
    jeopardyTeam?: JeopardyTeam
}

export interface ExternalUser {
    user: User
    quickBooksEmployee?: QuickBooksEmployee
    jiraUser?: JiraUser
    authUser?: Partial<User>
}

export interface QuickBooksCustomer {
    Id: string
    SyncToken: string
}

export interface QuickBooksEmployee {
    Id: string
    GivenName: string
    MiddleName: string
    FamilyName: string
    PrimaryAddr: {
        Line1: string
        City: string
        CountrySubDivisionCode: string
        PostalCode: string
    }
    PrimaryEmailAddr: {
        Address: string
    }
    HiredDate: string
    SyncToken: string
    Active: boolean
}

export interface JiraUser {
    emailAddress: string
    products?: string[]
    groups?: string[]
    applicationRoles?: {
        items: { key: string; name: string }[]
    }
}

export interface TempPasswordRules {
    requiresNumber?: boolean
    requiresUpperAndLower?: boolean
    requiresSpecialCharacters?: boolean
}

export interface GenericEmailInformation {
    toEmailList: string[]
    subject: string
    message: string
}

export interface TSheetsServiceItemFilterData {
    serviceItemId: number
    serviceItemName: string
    serviceItemUsers: NewTSheetsServiceItemUser[]
    projectId: number
    epicId: number
}

export interface TSheetsServiceItemInformation {
    serviceItem: ServiceItem|null
    epicServiceItem: EpicServiceItem|null
    isSetup: boolean
}

export interface SecureNote {
    type: 'login'|'password'|'keys'|'note'
    title: string
    createdById: string
    createdByName: string
    createdByEmail: string
    token: string
    instructions: string
    receiverEmail: string
    username: string
    password: string
    website: string
    clientId: string
    publicKey: string
    privateKey: string
    note: string
    expireTime: string
    expireDateTime: string
}

export interface Customer {
    id: number
    createdAt: Date
    updatedAt: Date
    accountId: string
    name: string
    email: string
    shouldRemove?: boolean
}

export interface EditingTask {
    id?: number
    projectId: number
    epicServiceItemId: number
    assigneeUserId: number
    summary: string
    issueEstimateSeconds: number
    beginDate: Date|null
    frequency: TaskIssueInformationFrequency
    lastCreatedDate: Date
    labels: string[]
    daysOfWeek: number[]
    occurrence: string|null
    collaborators: Partial<RecurringTaskCollaborator>[]
}

export interface Task {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    enabled: boolean
}

export interface RecurringTask {
    id: number
    createdAt: Date
    updatedAt: Date
    projectId: number
    epicServiceItemId: number
    assigneeUserId: number
    summary: string
    issueEstimateSeconds: number
    beginDate: Date|null
    frequency: TaskIssueInformationFrequency
    lastCreatedDate: Date
    labels: string[]
    daysOfWeek: number[]
    occurrence: string
    project?: Project
    epicServiceItem?: EpicServiceItem
    assigneeUser?: User
    recurringTaskCollaborators?: RecurringTaskCollaborator[]
}

export interface RecurringTaskCollaborator {
    id: number
    createdAt: Date
    updatedAt: Date
    recurringTaskId: number
    userId: number
    recurringTask?: RecurringTask
    user?: User
}

export interface ContractTemplate {
    id: number
    createdAt: Date
    updatedAt: Date
    templateName: string
    contractTemplateType: ContractTemplateType
    hasFile: boolean
    contractTemplateUploadType: ContractTemplateUploadType
    zohoFieldTypes: ZohoFieldType[]
    zohoFieldTypeContractTemplates: ZohoFieldTypeContractTemplate[]
}

export interface ContractTemplateName {
    contractTemplateName: string
    contractTemplateType: ContractTemplateType
    contractTemplateUploadType: ContractTemplateUploadType
}

export interface ZohoFieldType {
    id: number
    createdAt: Date
    updatedAt: Date
    name: string
    type: string
    contractTemplates: ContractTemplate[]
    zohoFieldTypeContractTemplates: ZohoFieldTypeContractTemplate[]
    zohoFieldTypeSpecialFormats: ZohoFieldTypeSpecialFormat[]
}

export interface ZohoFieldTypeContractTemplate {
    id: number
    createdAt: Date
    updatedAt: Date
    zohoFieldTypeId: number
    contractTemplateId: number
    contractTemplate: ContractTemplate
    zohoFieldType: ZohoFieldType
}

export interface ZohoFieldTypeSpecialFormat {
    id: number
    createdAt: Date
    updatedAt: Date
    zohoFieldTypeContractTemplateId: number
    formatName: string
    formatValue: string|number
    splitType: SplitType|null
    zohoFieldTypeContractTemplate: ZohoFieldTypeContractTemplate
}

export interface ZohoDealAndCommissionFilter {
    zohoClientId: number|null
    commissionAssignmentUserId: number|null
    commissionEffectiveDate: string|null
    notAppliedOnly?: boolean
}

export interface ZohoCommissionAssignments {
    executiveCoaching: {
        userId: number|null
        email: string|null
        specialNotes: string|null
    }
    leadGen: {
        userId: number|null
        email: string|null
        specialNotes: string|null
    }
    primarySupport: {
        userId: number|null
        email: string|null
        specialNotes: string|null
    }
    sales: {
        userId: number|null
        email: string|null
        specialNotes: string|null
    }
}

export interface InvoiceFilter {
    epicQuickBooksId?: string
    status?: 'paid' | 'unpaid'
    [index: string]: string | 'paid' | 'unpaid' | null | undefined
}

export interface Invoice {
    id: number
    createdAt: Date
    updatedAt: Date
    epicId: number
    transactionDate: string
    total: number
    balance: number
    version: number
    quickBooksId: string
    paidInFull: boolean
    partiallyPaid: boolean
    unpaid: boolean
    epic?: Epic
    appliedInvoiceZohoCommissions?: AppliedInvoiceZohoCommission[]
}

export interface InvoiceDev {
    id: number
    createdAt: Date
    updatedAt: Date
    epicId: number
    transactionDate: string
    total: number
    balance: number
    version: number
    quickBooksId: string
    paymentQuickBooksId: string|null
    paidInFull: boolean
    partiallyPaid: boolean
    unpaid: boolean
    epic?: Epic
    appliedInvoiceZohoCommissions?: AppliedInvoiceZohoCommissionDev[]
}

export interface AppliedInvoiceZohoCommission {
    id: number
    createdAt: Date
    updatedAt: Date
    zohoDealCommissionId: number
    invoiceId: number
    invoice?: Invoice
    zohoDealCommission?: ZohoDealCommission
}

export interface AppliedInvoiceZohoCommissionDev {
    id: number
    createdAt: Date
    updatedAt: Date
    zohoDealCommissionId: number
    invoiceId: number
    invoice?: InvoiceDev
    zohoDealCommission?: ZohoDealCommission
}

export interface Bill {
    id: number
    createdAt: Date
    updatedAt: Date
    quickBooksId: string
    vendorId: number
    version: number
    total: number
    balance: number
    transactionDate: string
    paymentQuickBooksId: string|null
    description: string
    vendor?: Vendor
    billLineItems?: BillLineItem[]
}

export interface BillDev {
    id: number
    createdAt: Date
    updatedAt: Date
    quickBooksId: string
    vendorId: number
    version: number
    total: number
    balance: number
    transactionDate: string
    paymentQuickBooksId: string|null
    description: string|null
    vendor?: VendorDev
    billLineItems?: BillLineItemDev[]
}

export interface BillLineItem {
    id: number
    createdAt: Date
    updatedAt: Date
    billId: number
    amount: number
    description: string|null
    quickBooksId: string
    commissionAdjustment: number
    epicId: number|null
    adjustedTotal: number
    bill?: Bill
    epic?: Epic
    appliedBillLineItemZohoCommissions?: AppliedBillLineItemZohoCommission[]
}

export interface BillLineItemDev {
    id: number
    createdAt: Date
    updatedAt: Date
    billId: number
    amount: number
    description: string|null
    quickBooksId: string
    commissionAdjustment: number
    epicId: number|null
    adjustedTotal: number
    bill?: BillDev
    epic?: Epic
    appliedBillLineItemZohoCommissions?: AppliedBillLineItemZohoCommissionDev[]
}

export interface AppliedBillLineItemZohoCommission {
    id: number
    createdAt: Date
    updatedAt: Date
    zohoDealCommissionId: number
    billLineItemId: number
    billLineItem?: BillLineItem
    zohoDealCommission?: ZohoDealCommission
}

export interface AppliedBillLineItemZohoCommissionDev {
    id: number
    createdAt: Date
    updatedAt: Date
    zohoDealCommissionId: number
    billLineItemId: number
    billLineItem?: BillLineItemDev
    zohoDealCommission?: ZohoDealCommission
}

export interface Vendor {
    id: number
    createdAt: Date
    updatedAt: Date
    version: number
    firstName: string|null
    lastName: string|null
    companyName: string|null
    displayName: string
    quickBooksId: string
    active: boolean
    bills?: Bill[]
}

export interface VendorDev {
    id: number
    createdAt: Date
    updatedAt: Date
    version: number
    firstName: string|null
    lastName: string|null
    companyName: string|null
    displayName: string
    quickBooksId: string
    active: boolean
    bills?: BillDev[]
}
