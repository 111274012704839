import { defineStore, storeToRefs } from 'pinia'
import { addDays, addMonths, differenceInDays, endOfMonth, format } from 'date-fns'
import { Ticket, TimeEntryPayPeriod, TimeEntryPayPeriodTab, Worklog } from '@/types'
import TicketService from '@/services/ticket-service'
import WorklogService from '@/services/worklog-service'
import Common from '@/util/common'
import ActivityTimelineService from '@/services/activity-timeline-service'
import { useGlobalStore } from '@/stores/global'

interface TimeActivityState {
    payPeriods: TimeEntryPayPeriod[]
    selectedPayPeriod: TimeEntryPayPeriod|null
    payPeriodTabs: TimeEntryPayPeriodTab[]
    overheadTickets: Ticket[]
    favoritesTickets: Ticket[]
    recentlyCreatedTickets: Ticket[]
    payPeriodWorklogs: Worklog[]
    timelineWorklogs: Worklog[]
    activityTimelines: any[]
    activityTimelineHeaders: any[]
    numTimelineDays: number
    numTimelineMaxTasks: number
    defaultBarHeight: number
    defaultBarWidth: number
    defaultGrid: number[]
    isValidJiraKey: boolean
    isSaving: boolean
    jiraBaseUrl: string
    createdJiraKey: string
}

export const useTimeActivityStore = defineStore('timeActivity', {
    state: (): TimeActivityState => {
        return {
            payPeriods: [],
            selectedPayPeriod: null,
            payPeriodTabs: [],
            overheadTickets: [],
            favoritesTickets: [],
            recentlyCreatedTickets: [],
            payPeriodWorklogs: [],
            timelineWorklogs: [],
            activityTimelines: [],
            activityTimelineHeaders: [],
            numTimelineDays: 14,
            numTimelineMaxTasks: 0,
            defaultBarHeight: 25,
            defaultBarWidth: 122,
            defaultGrid: [123, 30],
            isValidJiraKey: false,
            isSaving: false,
            jiraBaseUrl: '',
            createdJiraKey: '',
        }
    },

    actions: {
        getPayPeriodInformation() {
            const { isSuperAdmin } = storeToRefs(useGlobalStore())

            if (isSuperAdmin.value) {
                this.payPeriods = Common.getPayPeriods()
            } else {
                const today = new Date()
                const lastMonth = addMonths(today, -1)

                const previousStartDate = today.getDate() > 15 ? new Date(today.getFullYear(), today.getMonth(), 1) : new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 16)
                const previousEndDate = today.getDate() > 15 ? new Date(today.getFullYear(), today.getMonth(), 15) : endOfMonth(lastMonth)
                const previousPayPeriod: TimeEntryPayPeriod = {
                    type: 'previous',
                    startDate: previousStartDate,
                    endDate: previousEndDate,
                    rangeString: `Previous (${format(previousStartDate, 'EEE M/d/yyyy')} - ${format(previousEndDate, 'EEE M/d/yyyy')})`,
                }

                const currentStartDate = today.getDate() > 15 ? new Date(today.getFullYear(), today.getMonth(), 16) : new Date(today.getFullYear(), today.getMonth(), 1)
                const currentEndDate = today.getDate() > 15 ? endOfMonth(today) : new Date(today.getFullYear(), today.getMonth(), 15)
                const currentPayPeriod: TimeEntryPayPeriod = {
                    type: 'current',
                    startDate: currentStartDate,
                    endDate: currentEndDate,
                    rangeString: `Current (${format(currentStartDate, 'EEE M/d/yyyy')} - ${format(currentEndDate, 'EEE M/d/yyyy')})`,
                }

                this.payPeriods.push(currentPayPeriod)

                if (addDays(previousEndDate, 5) > today) {
                    this.payPeriods.push(previousPayPeriod)
                }
            }
        },

        getPayPeriodTabs() {
            if (this.selectedPayPeriod) {
                this.payPeriodTabs = []
                let arrayDate = this.selectedPayPeriod.startDate
                const endDate = this.selectedPayPeriod.endDate

                do {
                    this.payPeriodTabs.push({
                        date: arrayDate,
                        tabName: format(arrayDate, 'EEE M/d'),
                    })

                    arrayDate = addDays(arrayDate, 1)
                } while (arrayDate <= endDate)
            }
        },

        async getOverheadTickets(userId: number) {
            if (this.selectedPayPeriod) {
                const startDate = this.selectedPayPeriod.startDate
                const endDate = this.selectedPayPeriod.endDate

                this.overheadTickets = await TicketService.getOverheadTickets(Common.formatDate(startDate), Common.formatDate(endDate), userId)
            }
        },

        async getFavoritesTickets(userId: number) {
          this.favoritesTickets = await TicketService.getFavoritesTickets(userId)
        },

        async getRecentlyCreatedTickets(userId: number) {
            this.recentlyCreatedTickets = await TicketService.getRecentlyCreatedTickets(userId)
        },

        async updateFavoritesTicket(ticketId: number, jiraKey: string, userId: number, type: 'add'|'remove') {
            await TicketService.updateFavoritesTicket(ticketId, jiraKey, userId, type)
        },

        async getPayPeriodWorklogs(userId: number) {
            if (this.selectedPayPeriod) {
                this.payPeriodWorklogs = await WorklogService.getWorklogs(userId, null, Common.formatDate(this.selectedPayPeriod.startDate), Common.formatDate(this.selectedPayPeriod.endDate))
            }
        },

        async getTimelineWorklogs(userId: number, startDate: string, endDate: string) {
            this.timelineWorklogs = await WorklogService.getWorklogs(userId, null, startDate, endDate)
        },

        findRow(rows: any[], currentRowNumber: number, startDate: string, endDate: string): number {
            for (const row of rows) {
                let availableRow = true

                for (const bar of row.bars) {
                    if (new Date(endDate) >= new Date(bar.startDate) && new Date(startDate) <= new Date(bar.endDate)) {
                        availableRow = false
                        break
                    }
                }
                if (availableRow) {
                    return currentRowNumber
                }
                currentRowNumber++
            }

            return -1
        },

        async getActivityTimelines (dateRange: Date[], startDate: string, endDate: string, userId: number) {
            try {
                this.activityTimelines = []
                this.activityTimelineHeaders = []

                this.numTimelineDays = differenceInDays(dateRange[1], dateRange[0]) + 1

                const rows: any[] = []
                const results = await ActivityTimelineService.getUserActivityTimelines(startDate, endDate, false, userId)

                let barCounter = 0
                let rowNumber = 0
                let startRowNumber

                if (results?.length && results[0].activityTimelines?.length) {
                    startRowNumber = rowNumber
                    let counter = 1
                    const activityTimelines = results[0].activityTimelines

                    for (const activityTimeline of activityTimelines) {
                        const groupStartDate = (Common.formatDate(activityTimeline.startDate)).concat(' 00:00')
                        const groupEndDate = (Common.formatDate(activityTimeline.endDate)).concat(' 23:59')

                        const label = `${Common.getHoursFromSeconds(activityTimeline.remainingEstimateSeconds)}h - [${activityTimeline.jiraId ? activityTimeline.jiraKey : 'Placeholder'}] ${activityTimeline.description}${activityTimeline.ticket?.status ? ` - ${activityTimeline.ticket.status.name}` : ''}`
                        const bar = {
                            startDate: activityTimeline.startDate,
                            endDate: activityTimeline.endDate,
                            activityTimelineId: activityTimeline.id,
                            label: label,
                            tooltip: label,
                            jiraId: activityTimeline.jiraId || '',
                            jiraKey: activityTimeline.jiraKey,
                            ticketId: activityTimeline.ticket?.id || -1,
                            originalEstimateSeconds: activityTimeline.originalEstimateSeconds,
                            remainingEstimateSeconds: activityTimeline.remainingEstimateSeconds,
                            id: barCounter++,
                            isDone: activityTimeline.ticket?.status?.category === 'DONE',
                        }

                        const totalRowNumber = this.findRow(JSON.parse(JSON.stringify(rows)), startRowNumber, groupStartDate, groupEndDate)

                        if (totalRowNumber === -1) {
                            let label = ''
                            if (counter++ === 1) {
                                label = results[0].fullName
                            }
                            const row = {
                                id: activityTimeline.id,
                                label: label,
                                bars: [bar],
                            }
                            rows.push(row)
                            rowNumber++
                        } else {
                            rows[totalRowNumber].bars.push(bar)
                        }
                    }
                }

                const taskRows: any = {}
                let selectedDate = dateRange[0]
                let colNum = 0
                let i = -15
                do {
                    taskRows[0] = true
                    this.activityTimelines.push({
                        x: colNum,
                        s: 0, // s is considered y; y is behaving badly
                        y: 0,
                        w: this.defaultBarWidth,
                        h: this.defaultBarHeight,
                        i: i++,
                        label: format(selectedDate, 'EEE d'),
                        date: selectedDate,
                        tooltip: '',
                        jiraKey: '',
                        jiraId: '',
                        ticketId: -1,
                        activityTimelineId: -1,
                        originalEstimateSeconds: 0,
                        remainingEstimateSeconds: 0,
                        isDone: false,
                    })

                    colNum += this.defaultBarWidth + 1
                    selectedDate = addDays(selectedDate, 1)
                } while (selectedDate < addDays(dateRange[1], 1))

                let rowNum = 30

                for (const row of rows) {
                    for (const bar of row.bars) {
                        if (!this.activityTimelines.find(l => l.i === bar.id)) {
                            taskRows[rowNum] = true
                            const barStartDate = new Date(Common.dateOnly(bar.startDate).concat(' 12:00'))
                            const barEndDate = new Date(Common.dateOnly(bar.endDate).concat(' 12:00'))
                            const dateRangeStart = new Date(Common.dateOnly(dateRange[0]).concat(' 12:00'))
                            const dateRangeEnd = new Date(Common.dateOnly(dateRange[1]).concat(' 12:00'))
                            const numSquares = differenceInDays(barEndDate, barStartDate) === 0 ?
                                1 :
                                barStartDate <= dateRangeStart && barEndDate >= dateRangeEnd ?
                                    this.numTimelineDays :
                                    barStartDate >= dateRangeStart && barEndDate <= dateRangeEnd ?
                                        differenceInDays(barEndDate, barStartDate) + 1 :
                                        barStartDate >= dateRangeStart ?
                                            differenceInDays(dateRangeEnd, barStartDate) + 1 :
                                            differenceInDays(barEndDate, dateRangeStart) + 1
                            const width = differenceInDays(barEndDate, barStartDate) === 0 ?
                                this.defaultBarWidth :
                                barStartDate <= dateRangeStart && barEndDate >= dateRangeEnd ?
                                    (this.numTimelineDays * this.defaultBarWidth) + this.numTimelineDays - 2 :
                                    barStartDate >= dateRangeStart && barEndDate <= dateRangeEnd ?
                                        (numSquares * this.defaultBarWidth) + numSquares - 1 :
                                        barStartDate >= dateRangeStart ?
                                            (numSquares * this.defaultBarWidth) + numSquares - 2 :
                                            (numSquares * this.defaultBarWidth) + numSquares - 1

                            this.activityTimelines.push({
                                x: barStartDate <= dateRangeStart ?
                                    0 :
                                    (differenceInDays(barStartDate, dateRangeStart) * this.defaultBarWidth) + differenceInDays(barStartDate, dateRangeStart),
                                s: rowNum, // s is considered y; y is behaving badly
                                y: rowNum,
                                w: width,
                                h: this.defaultBarHeight,
                                i: bar.id,
                                originalStartDate: barStartDate,
                                startDate: barStartDate,
                                originalEndDate: barEndDate,
                                endDate: barEndDate,
                                label: bar.label.substring(0, 15 * numSquares),
                                tooltip: bar.label,
                                jiraId: bar.jiraId,
                                jiraKey: bar.jiraKey,
                                ticketId: bar.ticketId,
                                id: bar.activityTimelineId,
                                originalEstimateSeconds: bar.originalEstimateSeconds,
                                remainingEstimateSeconds: bar.remainingEstimateSeconds,
                                isDone: bar.isDone,
                            })
                        }
                    }
                    rowNum += this.defaultBarHeight + 5
                }

                this.numTimelineMaxTasks = Object.keys(taskRows).length + 1
            } catch (err) {
                console.log(err)
            }
        },

        async getJiraBaseUrl () {
            this.jiraBaseUrl = await Common.getJiraBaseUrl()
        },

        setSelectedPayPeriod(selectedPayPeriod: TimeEntryPayPeriod|null) {
            this.selectedPayPeriod = selectedPayPeriod
        },

        setIsValidJiraKey(isValidJiraKey: boolean) {
            this.isValidJiraKey = isValidJiraKey
        },

        setIsSaving(isSaving: boolean) {
            this.isSaving = isSaving
        },
    },
})
