import { defineStore } from 'pinia'
import ProjectService from '@/services/project-service'
import ReportService from '@/services/report-service'
import Common from '@/util/common'
import WorklogService from '@/services/worklog-service'
import UserService from '@/services/user-service'
import { FileOrientation, Option, PdfAction, Project, Report, ReportEmail, ReportProjectWorklog, User } from '@/types'
import { toRaw } from 'vue'
import ExportService from '@/services/export-service'
import { useToast } from 'vue-toastification'
import SettingService from '@/services/setting-service'

const toast = useToast()

interface HomeState {
    projects: Project[]
    projectsDropdown: Option[]
    reportProjectWorklogs: ReportProjectWorklog[]
    reports: Report[]
    reportDropdownList: Option[]
    selectedReportId: number
    selectedReportName: string
    selectedReportEmails: ReportEmail[]
    selectedReportStatus: boolean
    users: User[]
    actionRunning: boolean
    secureNoteExpireTime: number
}

export const useHomeStore = defineStore('home', {
    state: (): HomeState => {
        return {
            projects: [],
            projectsDropdown: [],
            reportProjectWorklogs: [],
            reports: [],
            reportDropdownList: [],
            selectedReportId: -1,
            selectedReportName: '',
            selectedReportEmails: [],
            selectedReportStatus: false,
            users: [],
            actionRunning: false,
            secureNoteExpireTime: 0,
        }
    },

    actions: {
        async getSecureNoteExpireTime() {
            try {
                const setting = await SettingService.getSetting('secureNoteExpireTime')

                this.secureNoteExpireTime = parseInt(setting.value as string)
            } catch (err) {
                console.log(err)
            }
        },

        async getProjects () {
            try {
                this.projects = await ProjectService.getProjects()
                if (this.projects && this.projects.length) {
                    this.projectsDropdown = (Common.getSelectOptions(toRaw(this.projects), ['name'], 'key'))
                }
            } catch (err) {
                console.log(err)
            }
        },

        async getUsers () {
            try {
                this.users = await UserService.getUsers('active', true)
            } catch (err) {
                console.log(err)
            }
        },

        async getWorklogsForReport (payload: { startDate: string; endDate: string; projectKeys: string[]; username: string }) {
            try {
                const startDate = Common.formatDate(payload.startDate)
                const endDate = Common.formatDate(payload.endDate)
                this.reportProjectWorklogs = await WorklogService.getWorklogsForReport(startDate, endDate, payload.projectKeys, payload.username)
            } catch (err) {
                console.log(err)
            }
        },

        async getReports() {
            try {
                this.reports = await ReportService.getReports(true)
                this.setReportDropdownList()
            } catch (err) {
                console.log(err)
            }
        },

        async downloadOrEmailPDF(pdfAction: PdfAction, projectKeys: string[], startDate: string, endDate: string,
                                 orientation: FileOrientation, includeZeytechFooter: boolean, reportName: string,
                                 showFullText: boolean) {
            this.setActionRunning(true, 'Report generation started')

            let message = ''
            let messageType: 'success'|'error'|'info' = 'info'

            try {
                for (const projectKey of projectKeys) {
                    const reportValues = {
                        projectKey: projectKey,
                        startDate: startDate,
                        endDate: endDate,
                    }

                    await ExportService.downloadOrEmailPdf(pdfAction, orientation, includeZeytechFooter, reportName, reportValues, showFullText)
                }

                message = 'Report generation complete'
            } catch (err) {
                message = `Error: ${err}`
                messageType = 'error'
            } finally {
                this.setActionRunning(false, message, messageType)
            }
        },

        setReportDropdownList() {
            this.reportDropdownList = [{
                text: 'Select a report',
                value: '',
            }]
            for (const report of toRaw(this.reports)) {
                this.reportDropdownList.push({
                    text: report.appText ? report.appText : report.name,
                    value: toRaw(report),
                })
            }
        },

        setSelectedReport(report: Report|string) {
            if (typeof report === 'string') {
                this.selectedReportId = -1
                this.selectedReportName = ''
                this.selectedReportEmails = []
                this.selectedReportStatus = false
            } else {
                this.selectedReportId = report.id
                this.selectedReportName = report.name
                this.selectedReportEmails = report.reportEmails ? report.reportEmails : []
                this.selectedReportStatus = report.active
            }
        },

        async updateSelectedReportStatus() {
            try {
                if (this.selectedReportId !== -1) {
                    await ReportService.updateReport({ id: this.selectedReportId, active: this.selectedReportStatus })
                }
            } catch (err) {
                console.log(err)
            }
        },

        setWorklogs(payload: ReportProjectWorklog[]) {
            this.reportProjectWorklogs = payload
        },

        setActionRunning(payload: boolean, message = '', messageType: 'success'|'error'|'info' = 'info', timeout = 3500) {
            this.actionRunning = payload

            Common.runAction(payload, message, messageType, timeout)
        },
    },
})
