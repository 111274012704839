import BillLineItemService from '@/services/bill_line_item_service'
import { BillLineItem } from '@/types'
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'

const toast = useToast()

interface BillLineItemState {
    isSaving: boolean
}

export const useBillLineItemStore = defineStore('billLineItem', {
    state: (): BillLineItemState => {
        return {
            isSaving: false,
        }
    },

    actions: {
        async saveCommissionAdjustment(id: number, commissionAdjustment: number) {
            try {
                this.isSaving = true

                await BillLineItemService.updateCommissionAdjustment(id, commissionAdjustment)

                toast.success('Adjustment saved')
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            } finally {
                this.isSaving = false
            }
        },
    },
})
